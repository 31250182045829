// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contributors-js": () => import("./../../../src/pages/contributors.js" /* webpackChunkName: "component---src-pages-contributors-js" */),
  "component---src-pages-editors-js": () => import("./../../../src/pages/editors.js" /* webpackChunkName: "component---src-pages-editors-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-netlifyneedsthis-js": () => import("./../../../src/pages/netlifyneedsthis.js" /* webpackChunkName: "component---src-pages-netlifyneedsthis-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-submit-js": () => import("./../../../src/pages/submit.js" /* webpackChunkName: "component---src-pages-submit-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */)
}

